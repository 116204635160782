<template>
  <v-dialog v-model="modalData.dialog" max-width="1650px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        New adjustment
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form v-model="formValid" ref="form">
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-btn
                  v-if="
                    (permissionCan('create') && !formModel.id) ||
                    permissionCan('update')
                  "
                  color="primary"
                  dark
                  class="mb-2"
                  @click="handleSaveModalForm"
                >
                  Adjust
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <div v-if="formModel.owner_name">
                  <p>User: {{ formModel.owner_name }}</p>
                  <p>Adjusted: {{ formModel.created_at }}</p>
                </div>
              </v-col>
            </v-row>
            <v-form v-model="formItemValid" ref="formItem">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-select
                    v-if="!formModel.id && types.stock"
                    v-model="itemInputData.typeObj"
                    :items="typesOfAdjustment"
                    :rules="requiredRules"
                    label="Type of adjustment"
                    item-text="translated"
                    item-value="key"
                    return-object
                    @change="resetItemInputData"
                    :id="dynamicID"
                    :error-messages="messages['type']"
                    @keyup="messages['type'] = ''"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-select
                    v-model="formModel.storage_id"
                    :items="calculatedStorageCollection"
                    :rules="requiredRules"
                    label="Select
                storage"
                    item-text="name"
                    item-value="id"
                    :id="dynamicID"
                    :error-messages="messages['storage_id']"
                    @keyup="messages['storage_id'] = ''"
                    :disabled="formModel.entities.length > 0 || formModel.id"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" sm="2" md="3">
                  <v-combobox
                    v-if="!formModel.id && typeOfAdjustment"
                    :items="itemCollection"
                    v-model="itemInputData.item"
                    item-text="ReferenceName"
                    :label="searchInpuText"
                    :return-object="true"
                    :disabled="isEditedItemData"
                    :rules="requiredRules"
                    :error-messages="messages['item']"
                    @input="handleInputItemSearch"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No item matching! Press <kbd>enter</kbd> to search
                            serial number
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <v-text-field
                    v-if="
                      !formModel.id &&
                      typeOfAdjustment &&
                      typeOfAdjustment != 'remove' &&
                      itemInputData.num_of_pcs == 1
                    "
                    v-model="itemInputData.serial_number"
                    label="Serial number"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-text-field
                    v-if="
                      !formModel.id &&
                      typeOfAdjustment &&
                      typeOfAdjustment != 'remove'
                    "
                    v-model="itemInputData.quantity"
                    label="Quantity"
                    :rules="requiredRules"
                    :id="dynamicID"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-text-field
                    v-if="
                      !formModel.id &&
                      typeOfAdjustment &&
                      typeOfAdjustment != 'remove' &&
                      typeOfAdjustment != 'edit' &&
                      itemInputData.item
                    "
                    v-model="itemInputData.item.quantityUnit"
                    label="Quantity unit"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-text-field
                    v-if="
                      !formModel.id &&
                      typeOfAdjustment &&
                      typeOfAdjustment != 'remove' &&
                      typeOfAdjustment != 'edit' &&
                      !itemInputData.serial_number
                    "
                    type="number"
                    min="1"
                    v-model="itemInputData.num_of_pcs"
                    label="No. of pcs"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-select
                    v-if="
                      statuses.system &&
                      itemInputData.typeObj &&
                      !formModel.id &&
                      typeOfAdjustment
                    "
                    v-model="itemInputData.status"
                    :items="
                      adjustmentStatuses.stock[
                        'adjustment_itemEntity_' + itemInputData.typeObj.value
                      ]
                    "
                    label="State"
                    item-text="translated"
                    item-value="key"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <v-text-field
                    v-if="
                      itemInputData.typeObj && !formModel.id && typeOfAdjustment
                    "
                    v-model="itemInputData.comment"
                    label="Comment"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-btn
                    v-if="
                      (permissionCan('create') &&
                        typeOfAdjustment &&
                        !formModel.id) ||
                      (permissionCan('update') &&
                        typeOfAdjustment &&
                        !formModel.id)
                    "
                    color="primary"
                    dark
                    class="mb-2"
                    @click="handleAddItemToList"
                  >
                    Add to list
                  </v-btn>
                </v-col>
              </v-row>
              <row v-if="entitySelected">
                <v-col cols="12" sm="12" md="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Reference</th>
                          <th>Item name</th>
                          <th>Serial number</th>
                          <th>Quantity</th>
                          <th>Quantity unit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ entitySelected.item_entity_id }}</td>
                          <td>{{ entitySelected.reference_number }}</td>
                          <td>{{ entitySelected.nameTranslated }}</td>
                          <td>{{ entitySelected.serial_number }}</td>
                          <td>{{ entitySelected.quantity }}</td>
                          <td>{{ entitySelected.quantityUnitTranslated }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </row>
            </v-form>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="formModel.entities"
                  class="elevation-1"
                  group-by="Pcs"
                  show-group-by
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template
                    v-slot:group.header="{ group, headers, toggle, isOpen }"
                  >
                    <td :colspan="headers.length">
                      <v-btn
                        @click="toggle"
                        small
                        icon
                        :ref="group"
                        :data-open="isOpen"
                      >
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>
                      {{ group }}
                    </td>
                  </template>
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Entities</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeDelete"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItemConfirm"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:item.name="{ item }">
                    <span> {{ item.item.name }}</span>
                  </template>

                  <template v-slot:item.reference="{ item }">
                    <span> {{ item.item.reference }}</span>
                  </template>

                  <template v-slot:item.quantityUnit="{ item }">
                    <span> {{ item.item.quantityUnit }}</span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      @click="deleteItem(item)"
                      v-if="!formModel.id"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
                <!-- <pre>
                  {{ formModel.entities }}
                </pre> -->
              </v-col>
            </v-row>
          </v-form>
          <!-- *******************
      Etity dialog
*******************-->

          <v-dialog v-model="entityModal" persistent max-width="1200px">
            <v-card>
              <v-card-title>
                <v-toolbar flat dense>
                  <v-toolbar-title>Select entity</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="entityModal = false"
                    style="float: right; cursor: pointer"
                    icon
                    color="#26223c"
                  >
                    <v-icon> mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-text-field
                    v-model="searchEntity"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-data-table
                        :headers="headersEntity"
                        :items="entitiesArray"
                        :search="searchEntity"
                        :items-per-page="-1"
                        :hide-default-footer="true"
                        disable-pagination
                      >
                        <template #item="{ item }">
                          <tr
                            @click="handleClickEntity(item)"
                            :style="[
                              !item.isSelectable
                                ? {
                                    color: 'grey',
                                  }
                                : { cursor: 'pointer' },
                            ]"
                          >
                            <td>
                              {{ item.item_entity_id }}
                            </td>
                            <td>{{ item.reference_number }}</td>
                            <td>{{ item.nameTranslated }}</td>
                            <td>{{ item.serial_number }}</td>
                            <td>{{ item.statusTranslated }}</td>
                            <td>{{ item.original_quantity }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.quantityUnitTranslated }}</td>
                            <td>{{ item.storage }}</td>
                            <td>{{ item.reserved_at }}</td>
                            <td>{{ item.reserved_by_name }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="entityModal = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>
        <!-- <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          Adjust
        </v-btn> -->
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import { mapGetters } from "vuex";
import { ENDPOINT } from "./Adjustments";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

const TRANSLATED_ATRIBUTES = [];

export const initialFormData = () => ({
  id: null,
  storage_id: null,
  owner_id: null,
  owner_name: null,
  created_at: null,
  entities: [],
});

export const initialItemInputData = {
  type: null,
  typeObj: {},
  item: null,
  num_of_pcs: 1,
  quantityUnit: null,
  serial_number: null,
  quantity: null,
  status: null,
  comment: "",
};

export default {
  loader: false,
  name: "AdjustmentForm",
  props: ["modalData"/* , "permissions" */],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      formItemValid: false,
      itemInputData: Object.assign({}, initialItemInputData),
      editedItem: Object.assign({}, initialItemInputData),
      editedIndex: null,
      dialogDelete: false,
      entityModal: false,
      entitySelected: null,
      entitiesArray: [],
      searchEntity: "",
      permissions: [],
      headersEntity: [
        { text: "ID", value: "item_entity_id" },
        { text: "Reference", value: "reference_number" },
        { text: "Item name", value: "nameTranslated" },
        { text: "Serial number", value: "serial_number" },
        { text: "Status", value: "statusTranslated" },
        { text: "Original quantity", value: "original_quantity" },
        { text: "Quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnitTranslated" },
        { text: "Storage", value: "storage" },
        { text: "Reserved at", value: "reserved_at" },
        { text: "Reserved by", value: "reserved_by_name" },
      ],

      headers: [
        { text: "Pcs", value: "Pcs" },
        { text: "ID", value: "item_entity_id", groupable: false },
        { text: "Type of adjustment", value: "typeOf", groupable: false },
        { text: "Reference", value: "reference", groupable: false },
        { text: "Item name", value: "name", groupable: false },
        {
          text: "Old serial number",
          value: "old_serial_number",
          groupable: false,
        },
        { text: "Serial number", value: "serial_number", groupable: false },
        { text: "Old state", value: "oldStatusTranslated", groupable: false },
        { text: "New state", value: "statusTranslated", groupable: false },
        { text: "Old quantity", value: "old_quantity", groupable: false },
        { text: "New quantity", value: "quantity", groupable: false },
        { text: "Quantity unit", value: "quantityUnit", groupable: false },
        { text: "Comment", value: "comment", groupable: false },
        { text: "Action", value: "actions", groupable: false },
      ],

      messages: {},
      stringRules: [
        (v) => !!v || "Field is required",
        (v) => v.length < 25 || "Field must be max 16 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  computed: {
    ...mapGetters([
      "statuses",
      "types",
      "itemCollection",
      "storageCollectionAll",
      "storageCollection",
    ]),
    adjustmentStatuses() {
      if (this.statuses) {
        let statuses = this.statuses;
        this.statuses.stock["adjustment_itemEntity_add"].map((item) => {
          item.translated = this.getTranslatedStatus(item.key);
          return item;
        });

        statuses.stock["adjustment_itemEntity_edit"] = this.statuses.stock[
          "adjustment_itemEntity_edit"
        ].map((item) => {
          item.translated = this.getTranslatedStatus(item.key);
          return item;
        });

        statuses.stock["adjustment_itemEntity_remove"] = this.statuses.stock[
          "adjustment_itemEntity_remove"
        ].map((item) => {
          item.translated = this.getTranslatedStatus(item.key);
          return item;
        });

        return statuses;
      }
      return [];
    },

    typesOfAdjustment() {
      if (this.types.stock.adjustmentEntity) {
        return this.types.stock.adjustmentEntity.map((item) => {
          item.translated = this.getTranslatedType(item.key);
          return item;
        });
      }
      return [];
    },

    statusesEdit() {
      return this.statuses.stock.itemEntity;
    },

    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? "View adjustment" + " - " + this.formModel.ad_number
        : "New adjustment";
    },

    searchInpuText() {
      if (this.itemInputData.typeObj.value == "add") return "Search item";
      if (
        this.itemInputData.typeObj.value == "remove" ||
        this.itemInputData.typeObj.value == "edit"
      )
        return "Search entity";
      return "";
    },

    typeOfAdjustment() {
      return this.itemInputData.typeObj.value;
    },

    calculatedStorageCollection() {
      if (this.itemInputData.typeObj.key == 1) {
        return this.storageCollection;
      }

      return this.storageCollectionAll;
    },
  },

  watch: {
    "modalData.dialog": {
      deep: true,
      handler(value) {
        if (value && !this.modalData.editedId) {
          this.itemInputData = Object.assign({}, initialItemInputData);
          this.formModel = Object.assign({}, initialFormData());
        }
        setTimeout(this.resetErrorMessages(), 1200, this.resetErrorMessages());
      },
    },

    "modalData.editedId": {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value) {
          this.loader = true;
          ApiService.get(ENDPOINT + value)
            .then(({ data }) => {
              for (var entity of data.entities) {
                entity.item = this.$store.getters.getItemByID(entity.item_id);
                entity.item.reference_number = entity.reference_number;
                entity.typeOf = this.$t("ADJUSTMENT.typeOf_" + entity.type);
                entity.item.serial_number = entity.serial_number;
                entity.quantity = entity.new_quantity;
                entity.oldStatusTranslated = this.getTranslatedStatus(
                  entity.old_status
                );
                entity.statusTranslated = this.getTranslatedStatus(
                  entity.new_status
                );
                entity.item.quantity_unit_id = entity.quantity_unit_id;
                entity.item.comment = entity.comment;
                entity.item.quantityUnit =
                  this.$store.getters.getQuantityUnitByID(
                    entity.quantity_unit_id
                  ).name;

                entity.quantityUnitTranslated = entity.item.quantityUnit;
                entity.nameTranslated = entity.item.name;
              }
              this.formModel = Object.assign({}, data);
              this.formModel.entities = this.calcEntityPcs(
                this.formModel.entities
              );
              setTimeout(() => {
                this.closeAll();
              }, 200);
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchStorage", "fetchItem", "fetchQuantityUnit"]),
    handleCloseModalForm() {
      let data = this.formModel.entities
        .filter((item) => {
          return item.type != 1;
        })
        .map((item) => {
          return item.item_entity_id;
        });

      if (!this.formModel.id && data.length > 0) {
        this.unReserveEntityByIds({
          ids: data,
        });
      }

      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        // this.modalData.loading = true;

        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            if (
              model.translations[langCode] &&
              model.translations[langCode].name != ""
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleItemChange() {
      if (this.itemInputData.item) {
        this.itemInputData.item.quantityUnit =
          this.$store.getters.getQuantityUnitByID(
            this.itemInputData.item.quantity_unit_id
          ).name;
      }
    },

    handleInputItemSearch(value) {
      this.entitySelected = null;
      if (value && typeof value === "object") {
        if (this.typeOfAdjustment == "add") {
          this.handleItemChange();
        } else if (this.typeOfAdjustment == "remove") {
          this.openEntityModalForm({
            storage_id: this.formModel.storage_id,
            statuses: [4],
            item_id: this.itemInputData.item.id,
          });
        } else if (this.typeOfAdjustment == "edit") {
          this.openEntityModalForm({
            storage_id: this.formModel.storage_id,
            statuses: [3, 4, 9, 10],
            item_id: this.itemInputData.item.id,
          });
        }
      } else if (this.typeOfAdjustment != "add") {
        this.openEntityModalForm({
          s: value,
          storage_id: this.formModel.storage_id,
          statuses: [3, 4, 9, 10],
        });
      }
    },

    openEntityModalForm(searchObj) {
      ApiService.post(ENDPOINT + "itemEntity/search", searchObj)
        .then((data) => {
          if (data.data.length > 0) {
            let dataTranslated = data.data.map((item) => {
              item.nameTranslated = this.$helpers.getTranslated(
                item.translations
              ).name;
              item.quantityUnitTranslated = this.$helpers.getTranslated(
                item.quantity_unit_translations
              ).name;
              item.statusTranslated = this.getTranslatedStatus(item.status);
              item.isSelectable = !item.reserved_at;
              return item;
            });
            this.entitiesArray = dataTranslated;
            this.entityModal = true;
          } else {
            Swal.fire({
              title: "",
              text: "Entity not found!",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleClickEntity(entity) {
      if (entity.isSelectable) {
        this.entitySelected = Object.assign({}, entity);
        this.itemInputData.serial_number = this.entitySelected.serial_number;
        this.itemInputData.quantity = this.entitySelected.quantity;
        this.entityModal = false;
      }
    },

    resetInpuDataForm() {
      this.entitiesArray = [];
      this.entitySelected = null;
      this.itemInputData = Object.assign({}, initialItemInputData);
    },

    getTranslatedStatus(id) {
      return this.$t("ADJUSTMENT.status_" + id);
    },

    getTranslatedType(id) {
      return this.$t("ADJUSTMENT.typeOf_" + id);
    },

    setTranslatedAttributes() {
      if (!this.formModel.id) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },

    handleAddItemToList() {
      this.$refs.formItem.validate();

      if (this.formItemValid) {
        this.itemInputData.quantityUnitTranslated =
          this.itemInputData.item.quantityUnit;
        this.itemInputData.reference_number = this.itemInputData.item.reference;
        this.itemInputData.nameTranslated = this.itemInputData.item.name;

        if (this.typeOfAdjustment == "add") {
          this.itemInputData.type = this.itemInputData.typeObj.key;
          this.itemInputData.item_id = this.itemInputData.item.id;
          this.itemInputData.typeOf = this.$t(
            "ADJUSTMENT.typeOf_" + this.itemInputData.typeObj.key
          );
          this.itemInputData.statusTranslated = this.$t(
            "ADJUSTMENT.status_" + this.itemInputData.status
          );

          this.itemInputData.Pcs = this.getItemPcs(this.itemInputData);

          for (let i = 0; i < this.itemInputData.num_of_pcs; i++) {
            this.formModel.entities.push(this.itemInputData);
          }
          this.itemInputData = Object.assign({}, initialItemInputData);

          this.formModel.entities = this.calcEntityPcs(this.formModel.entities);
          setTimeout(() => {
            this.closeAll();
          }, 200);
        }
        if (this.typeOfAdjustment == "remove") {
          let self = Object.assign({}, this);
          this.reserveEntityByIds({
            ids: [this.entitySelected.item_entity_id],
          }).then((data) => {
            if (data) {
              self.itemInputData.item = self.$store.getters.getItemByID(
                self.entitySelected.item_id
              );
              self.itemInputData.type = self.itemInputData.typeObj.key;
              self.itemInputData.typeOf = this.$t(
                "ADJUSTMENT.typeOf_" + self.itemInputData.typeObj.key
              );
              self.itemInputData.statusTranslated = this.$t(
                "ADJUSTMENT.status_" + self.itemInputData.status
              );

              self.itemInputData.serial_number =
                self.entitySelected.serial_number;
              self.itemInputData.item_entity_id =
                self.entitySelected.item_entity_id;
              self.itemInputData.old_quantity = self.entitySelected.quantity;
              self.itemInputData.quantity = self.entitySelected.quantity;

              self.itemInputData.item.quantityUnit =
                self.$store.getters.getQuantityUnitByID(
                  self.entitySelected.quantity_unit_id
                ).name;

              self.itemInputData.quantityUnitTranslated =
                self.itemInputData.item.quantityUnit;

              self.formModel.entities.push(self.itemInputData);
              self.formModel.entities = self.calcEntityPcs(
                self.formModel.entities
              );
              setTimeout(() => {
                this.closeAll();
              }, 200);
              self.itemInputData = Object.assign({}, initialItemInputData);
            } else {
              Swal.fire({
                title: "",
                text: "The entity is already reserved!",
                icon: "error",
              });
            }
          });
        }

        if (this.typeOfAdjustment == "edit") {
          let self = Object.assign({}, this);
          this.reserveEntityByIds({
            ids: [this.entitySelected.item_entity_id],
          }).then((data) => {
            if (data) {
              self.itemInputData.item = self.$store.getters.getItemByID(
                self.entitySelected.item_id
              );
              self.itemInputData.type = self.itemInputData.typeObj.key;
              self.itemInputData.typeOf = this.$t(
                "ADJUSTMENT.typeOf_" + self.itemInputData.typeObj.key
              );
              self.itemInputData.statusTranslated = this.$t(
                "ADJUSTMENT.status_" + self.itemInputData.status
              );

              self.itemInputData.old_serial_number =
                self.entitySelected.serial_number;
              self.itemInputData.item_entity_id =
                self.entitySelected.item_entity_id;
              self.itemInputData.old_quantity = self.entitySelected.quantity;

              self.itemInputData.item.quantityUnit =
                self.$store.getters.getQuantityUnitByID(
                  self.entitySelected.quantity_unit_id
                ).name;

              self.formModel.entities.push(self.itemInputData);
              self.formModel.entities = self.calcEntityPcs(
                self.formModel.entities
              );
              setTimeout(() => {
                this.closeAll();
              }, 200);
              self.itemInputData = Object.assign({}, initialItemInputData);
            } else {
              Swal.fire({
                title: "",
                text: "The entity is reserved!",
                icon: "error",
              });
            }
          });
        }
        this.entitySelected = null;
        this.itemInputData = Object.assign({}, initialItemInputData);
        this.$refs.formItem.resetValidation();
      }
    },

    getItemPcs(item) {
      return (
        " " +
        item.reference_number +
        " " +
        item.nameTranslated +
        " " +
        item.quantity +
        item.quantityUnitTranslated
      );
    },

    calcEntityPcs(entities) {
      if (!entities || typeof entities == "undefined") {
        return [];
      }
      let pcsArray = [];

      entities = entities.map((item) => {
        item.Pcs = this.getItemPcs(item);
        if (pcsArray.indexOf(item.Pcs) === -1) {
          pcsArray.push(item.Pcs);
        }
        return item;
      });

      pcsArray.forEach((pcs) => {
        let sum = 0;
        entities
          .filter((item) => {
            if (item.Pcs == pcs) sum++;
            return item.Pcs == pcs;
          })
          .map((item) => {
            if (item.Pcs.indexOf("pcs") == -1) {
              item.Pcs = item.Pcs + " - " + sum + " pcs";
            }
            return item;
          });
      });

      console.log(pcsArray);
      return entities;
    },

    resetItemInputData() {
      this.itemInputData.item = null;
      this.itemInputData.quantityUnit = null;
      this.itemInputData.serial_number = null;
      this.itemInputData.quantity = null;
      this.itemInputData.status = null;
      this.itemInputData.comment = null;
    },

    reserveEntityByIds(entityIds) {
      return ApiService.post(ENDPOINT + "itemEntity/reserve", entityIds);
    },

    unReserveEntityByIds(entityIds) {
      ApiService.post(ENDPOINT + "itemEntity/unreserve", entityIds)
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.formModel.entities.indexOf(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let itemToDelete = this.formModel.entities[this.editedIndex];

      if (itemToDelete.type != 1) {
        this.unReserveEntityByIds({
          ids: [itemToDelete.item_entity_id],
        });
      }

      this.formModel.entities.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = null;
      });
    },

    closeAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && this.$refs[k].$attrs["data-open"]) {
          this.$refs[k].$el.click();
        }
      });
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, initialFormData());

      if (this.$refs.form) this.$refs.form.resetValidation();

      this.languages.forEach((language) => {
        TRANSLATED_ATRIBUTES.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("adjustment") > -1
        );
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "stock.adjustment." + action
      );
    },
  },

  async mounted() {
    this.fetchItem();
    this.fetchStorage();
    this.fetchQuantityUnit();
    this.setTranslatedAttributes();
    this.setPermissions();
  },
};
</script>
